<template>
   <v-app>
      <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
          </v-app-bar-title>
      </v-app-bar>
      <v-main >
        <v-container color="transparent">
          <v-row class="pt-10">
             <v-spacer/>
             <v-col cols="10">
            <div class="text-subtitile-1 font-weight-bold natural--text text--darken-4"><v-btn @click="$router.push({name: 'selecttenant'})" text><v-icon color="natural darken-4">mdi-arrow-left</v-icon><span class="text-subtitile-1 font-weight-bold natural--text text--darken-4">ย้อนกลับ</span></v-btn></div>
             </v-col>
              <v-spacer/>
          </v-row>
          <v-row dense>
            <v-spacer/>
            <v-col cols="10">
         <SiteInfoCard :standaloneMode="true" :title="'สร้างหน่วยบริการ'"/>
            </v-col>
             <v-spacer/>
          </v-row>
        </v-container>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
   </v-app>
</template>

<script>
import SiteInfoCard from '@/components/site/SiteInfoCard'
export default {
  components: {
    SiteInfoCard
  },
  data () {
    return {
      data: '',
      loading: false,
      agree: false,
      selectedTenant: null
    }
  },
  methods: {
    fetchData () {
      // this.loading = true
      // this.data = JSON.parse(sessionStorage.getItem('Tenant'))
      // this.selectedSite = JSON.parse(sessionStorage.getItem('Tenant')).site[0]
      // this.loading = false
    },
    setTenant () {
      // sessionStorage.setItem('Site', JSON.stringify(this.selectedSite))
      // this.$auth.validatePage()
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
