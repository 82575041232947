<template>
  <v-card flat class="ma-0 pa-0">
   <v-card flat outlined >
      <v-card-title  v-if="standaloneMode" class="text-h4 font-weight-bold natural--text text--darken-4">
         {{title}}
      </v-card-title>
       <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" md="2">
                    <div class="text-center pt-5">
                    <v-avatar size="128">
                        <v-img :src="require('@/assets/defaultsiteavatar.svg')"/>
                    </v-avatar>
                    </div>
                </v-col>
                <v-divider vertical class="mx-4" v-if="!standaloneMode"/>
                 <v-col cols="12" md="9" >
                    <v-row dense class="pt-5">
                      <v-col cols="12">
                        <template v-if="standaloneMode">
                        ค้นหาโดยเลขที่หรือชื่อหน่วยบริการ
                        <v-autocomplete outlined dense
                          v-model="currentSite"
                          :items="items"
                          :loading="autocmpLoading"
                          :search-input.sync="search"
                          item-text="siteName"
                          item-value="healthProviderCode9"
                          return-object
                        >
                        </v-autocomplete>
                        </template>
                    <TextField dense :title="'เลขที่หน่วยบริการ'" v-model="currentSite.healthProviderCode9" :placeholder="'เลขที่หน่วบริการ'" />
                      </v-col>
                    </v-row>
                     <v-row dense>
                        <v-col cols="12">
                    <TextField dense :title="'ชื่อหน่วยบริการ'" v-model="currentSite.siteName" :placeholder="'ชื่อหน่วยบริการ'" :rules="siteNameRules"/>
                        </v-col>
                     </v-row>
                     <v-row dense>
                       <v-col cols="12">
                     <DropDownList dense :title="'กรม'"  :placeholder="'กรม'" v-model="currentSite.departmentCode" item-text="departmentName" item-value="departmentCode" :items="departmentItems"/>
                       </v-col>
                       <v-col cols="12">
                      <DropDownList dense :title="'กระทรวง'"  :placeholder="'กระทรวง'" v-model="currentSite.ministryCode" item-text="ministryName" item-value="ministryCode" :items="ministryItems"/>
                       </v-col>
                     </v-row>
                     <v-row dense>
                       <v-col cols="12">
                    <DropDownList  dense :title="'ประเภทหน่วยบริการ'" :placeholder="'ประเภทหน่วยบริการ'" :items="providerItems" item-text="hospitalTypeName" item-value="hospitalTypeCode" v-model="currentSite.hospitalTypeCode"/>
                       </v-col>
                         <v-col cols="12">
                    <DropDownList :rules="areaHealthRules" dense :title="'เขตสุขภาพ'" item-text="areaHealth" item-value="areaHealth" :placeholder="'เขตสุขภาพ'" :items="areaHealthItems" v-model="currentSite.areaHealth"/>
                       </v-col>
                        <v-col cols="12">
                    <TextField :rules="addressRules" dense :title="'ที่อยู่'" :placeholder="'ที่อยู่'" v-model="currentSite.address"/>
                        </v-col>
                         <v-col cols="12">
                   <v-row dense>
                    <v-col cols="4"> <DropDownList :rules="provinceRules" dense :title="'จังหวัด'" :placeholder="'จังหวัด'" :items="provinceItems" item-text="province" item-value="province" v-model="currentSite.province"/></v-col>
                    <v-col cols="4"> <DropDownList :rules="districtRules" dense :title="'อำเภอ'" :placeholder="'อำเภอ'" :items="districtItems" item-text="district" item-value="district" v-model="currentSite.district"/></v-col>
                    <v-col cols="4"> <DropDownList :rules="subDistrictRules" dense :title="'ตำบล'" :placeholder="'ตำบล'" :items="subDistrictItems" item-text="subdistrict" item-value="subdistrict" v-model="currentSite.subdistrict"/></v-col>
                   </v-row>
                        </v-col>
                      <v-col cols="12">
                    <TextField dense :title="'เบอร์ติดต่อ'" :placeholder="'เบอร์ติดต่อ'" v-model="currentSite.tel"/>
                      </v-col>
                      <v-col cols="12">
                    <TextField dense :title="'อีเมล์'" :placeholder="'อีเมล์'" v-model="currentSite.email"/>
                      </v-col>
                       <v-col cols="12">
                    <TextFieldQR dense :title="'รหัสหน่วยบริการ'" :placeholder="'รหัสหน่วยบริการ'" v-model="currentSite.inviteCode"/>
                      </v-col>
                     </v-row>
                 </v-col>
            </v-row>
       </v-form>
        </v-card>
        <v-card-actions class="mr-0 pr-0">
           <v-spacer/><v-btn v-if="($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)" large color="primary" width="250" @click="postSite" :loading="loading">บันทึก</v-btn>
        </v-card-actions>
         <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import TextField from '@/components/common/TextField'
import TextFieldQR from '@/components/common/TextFieldQR'
import DropDownList from '@/components/common/DropDownList'
import { getHealthProvider, getAreaHealth, getProvince, getProviderType, getDistrict, getSubDistrict, postSite, getMinistry, getDepartment, patchSite } from '@/api/'
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    standaloneMode: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  components: {
    TextField,
    DropDownList,
    TextFieldQR
  },
  data () {
    return {
      loading: false,
      site: {},
      search: null,
      items: [],
      autocmpLoading: false,
      model: {},
      valid: false,
      areaHealthItems: [],
      provinceItems: [],
      providerItems: [],
      districtItems: [],
      subDistrictItems: [],
      departmentItems: [],
      ministryItems: [],
      province: null,
      district: null,
      subDistrict: null,
      siteNameRules: [
        v => !!v || 'กรุณาระบุหมายชื่อหน่วยบริการ'
      ],
      addressRules: [
        v => !!v || 'กรุณาระบุที่อยู่'
      ],
      areaHealthRules: [v => !!v || 'กรุณาเลือกเขตสุขภาพ'],
      provinceRules: [v => !!v || 'กรุณาเลือกจังหวัด'],
      districtRules: [v => !!v || 'กรุณาเลือกอำเภอ'],
      subDistrictRules: [v => !!v || 'กรุณาเลือกตำบล']
    }
  },
  computed: {
    ...mapFields(['currentSite', 'postStatus'])
  },
  watch: {
    async search (keyword) {
      // Items have already been loaded
      if (keyword.length < 5) {
        this.items = []
        return
      }
      if (this.items.length > 0) return
      // Items have already been requested
      if (this.autocmpLoading) return
      this.autocmpLoading = true
      try {
        const resp = await getHealthProvider({ keyword: keyword })
        console.log(resp)
        if (resp.data.code === 1) {
          resp.data.result.forEach(function (item) {
            if (item.healthProviderCode9) {
              item.siteName = item.healthProviderCode9 + ' ' + item.healthProviderName
            } else {
              item.siteName = item.healthProviderName
            }
          })
          this.items = resp.data.result
        } else {
          this.items = []
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.autocmpLoading = false
      }
    },
    'currentSite.province': async function (val) {
      if (val) {
        try {
          const resp = await getDistrict({ province: val })
          if (resp.data.code === 1) {
            this.districtItems = resp.data.result
          } else {
            this.districtItems = []
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    'currentSite.district': async function (val) {
      if (val) {
        try {
          const resp = await getSubDistrict({ district: val })
          if (resp.data.code === 1) {
            this.subDistrictItems = resp.data.result
          } else {
            this.subDistrictItems = []
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        let resp = await getAreaHealth()
        if (resp.data.code === 1) {
          this.areaHealthItems = resp.data.result
        } else {
          this.areaHealthItems = []
        }
        resp = await getProvince({ areaHealth: '' })
        if (resp.data.code === 1) {
          this.provinceItems = resp.data.result
        } else {
          this.provinceItems = []
        }
        resp = await getProviderType()
        if (resp.data.code === 1) {
          this.providerItems = resp.data.result
        } else {
          this.providerItems = []
        }
        resp = await getMinistry()
        if (resp.data.code === 1) {
          this.ministryItems = resp.data.result
        } else {
          this.ministryItems = []
        }
        resp = await getDepartment()
        if (resp.data.code === 1) {
          this.departmentItems = resp.data.result
        } else {
          this.departmentItems = []
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    postSite () {
      if (this.$refs.form.validate()) {
        if (this.$auth.role) {
          if (this.standaloneMode) {
            const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
            const data = { ...this.currentSite, tenantId: tenant.tenantId }
            postSite(data, message => {
              if (message.data.code === 1) {
                sessionStorage.setItem('Site', JSON.stringify(message.data.result))
                this.postStatus = { code: true, message: 'สร้างหน่วยบริการใหม่เรียบร้อย !' }
                this.$router.push({ name: 'sitesettings' })
              }
            }, error => {
              console.log(error)
            })
          } else {
            this.loading = true
            const data = this.currentSite
            const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
            patchSite({ id: this.currentSite.id, etag: this.currentSite._etag, tenantId: tenant.tenantId, siteId: this.currentSite.siteId }, data, message => {
              if (message.data.code === 1) {
                this.currentSite = message.data.result
                this.postStatus = { code: true, message: 'สร้างหน่วยบริการใหม่เรียบร้อย !' }
              }
              this.loading = false
            }, error => {
              console.log(error)
              this.loading = false
            })
          }
        }
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }

}
</script>

<style>

</style>
