<template>
        <div>
       <label class="text-title-4 natural--text text--darken-4">{{title}} <span v-if="$attrs.required" class="error--text">*</span></label>
       <!-- </div> -->
        <v-text-field outlined
        v-bind="$attrs"
        v-on="inputListeners"
        append-icon="mdi-qrcode"
        @click:append="renderQR($attrs.value)"
        ></v-text-field>
        <v-dialog v-model="dialog" width="300">
            <v-card>
                <v-card-text><v-img :src="url"/></v-card-text>
            </v-card>
        </v-dialog>
        </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: ['title'],
  data () {
    return {
      dialog: false,
      url: null
    }
  },
  methods: {
    renderQR (data) {
      QRCode.toDataURL(data)
        .then(url => {
          this.url = url
          this.dialog = true
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field--outlined ::v-deep fieldset {
  border-color: #DBDCE7;
}
</style>
