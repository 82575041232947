<template>
   <v-app>
      <AppBar/>
      <!-- <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
          </v-app-bar-title>
      </v-app-bar> -->
      <v-main >
        <v-container color="transparent">
          <v-row class="pt-10">
            <v-spacer/>
            <v-col>
          <v-card width="614" flat>
            <v-card-text class="text-center">
                  <v-img class="mx-auto" width="196" contain  :src="require('@/assets/chooseSite.png')"></v-img>
              <div class="pt-5 text-h4 font-weight-bold natural--text text--darken-4">
                เลือกหน่วยบริการ
              </div>
              <div class="pt-5 pr-10 pl-10 mr-5 ml-5">
                <Select :items="data" item-text="siteName" v-model="selectedSite" return-object/>
              </div>
              <v-btn height="54" width="195" color="primary" @click="setTenant"><span class="text-title-2">ต่อไป</span></v-btn>
            </v-card-text>
          </v-card>
            </v-col>
            <v-spacer/>
          </v-row>
        </v-container>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
   </v-app>
</template>

<script>
import Select from '@/components/common/Select'
import { getSites } from '@/api/'
import AppBar from '@/components/common/AppBar'

export default {
  components: {
    Select,
    AppBar
  },
  data () {
    return {
      data: [],
      loading: false,
      agree: false,
      selectedTenant: null,
      selectedSite: null
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      if (this.$auth.isTenantAdmin || this.$auth.role.isSystemAdmin) {
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getSites({
          tenantId: tenant.tenantId,
          siteId: '',
          siteName: ''
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              this.data = message.data.result.items
              this.selectedSite = message.data.result.items[0]
              if (this.data.length === 0) this.$router.push({ name: 'createnewsite' })
              // this.items = message.data.result.items
            } else {
              this.data = []
              // this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      } else {
        this.data = JSON.parse(sessionStorage.getItem('Tenant')).site
        this.selectedSite = JSON.parse(sessionStorage.getItem('Tenant')).site[0]
        this.loading = false
      }
    },
    setTenant () {
      sessionStorage.setItem('Site', JSON.stringify(this.selectedSite))
      this.$auth.isSiteAdmin = this.selectedSite.isSiteAdmin
      this.$auth.validatePage()
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
