<template>

       <label class="text-title-4 natural--text text--darken-4">{{title}}<span v-if="$attrs.required" class="error--text">*</span>
        <v-select outlined
        v-bind="$attrs"
        v-on="inputListeners"
        ></v-select>
       </label>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select.v-text-field--outlined ::v-deep fieldset {
  border-color: #DBDCE7;
}
</style>
